<template>
  <div v-loading="loading" class="member-invite-edit">
    <PageTitle
      title="編輯推薦會員活動設定"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({name: 'MemberInviteSetting'})"
    />

    <div v-loading="!loaded" class="side-highlight-container">
      <el-form ref="formRef" style="max-width: 560px" :model="formData" :rules="formRules" label-position="top">
        <section>
          <ContainerTitle class="mb-[8px]" title="啟用狀態" hideTooltip />
          <el-form-item prop="displayReferralActivity">
            <template slot="label">
              <FormItemTooltipLabel label="啟用「推薦好友」活動" :tooltipWidth="200">
                若為「關閉」，則用戶端會員卡不顯示推薦好友的相關入口。後台保留相關紀錄。
              </FormItemTooltipLabel>
            </template>
            <el-switch
              v-model="formData.displayReferralActivity"
              inactive-text="關閉"
              active-text="開啟"
            />
          </el-form-item>
          <!-- <el-form-item prop="allowReferralRegister">
            <template slot="label">
              <FormItemTooltipLabel label="註冊流程顯示「填寫推薦碼」欄位" :tooltipWidth="200">
                若為「關閉」，則訪客在註冊流程填寫資料時不會出現「填寫推薦碼」欄位
              </FormItemTooltipLabel>
            </template>
            <el-switch
              v-model="formData.allowReferralRegister"
              inactive-text="隱藏"
              active-text="顯示"
              @change="handlerChange"
            />
          </el-form-item>

          <p v-show="formData.allowReferralRegister" class="text-danger font-bold text-sm">
            請確認「組織登入設定 > 註冊設定 > 簡易註冊設定」的開啟狀態。若為「關閉」，才會在註冊流程出現填寫推薦碼欄位
          </p> -->
        </section>

        <section>
          <ContainerTitle title="活動頁設定" tooltip=" 此文字將顯示在客人端「推薦好友」活動頁面，作為說明文字" />
          <el-form-item label="說明文字設定" prop="description">
            <template slot="label">
              <div class="inline-flex justify-between items-center w-full">
                <p>說明文字設定</p>
                <el-button plain @click="formData.description = defaultInfoText">套用預設文字</el-button>
              </div>
            </template>

            <el-input
              v-model="formData.description"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }"
              placeholder="請輸入"
              maxlength="200"
              show-word-limit
            />
          </el-form-item>
        </section>

        <section>
          <ContainerTitle title="會員分享 Line 訊息設定">
            <template slot="tooltip">
              1. 受邀請的好友會在 Line 訊息收到設定好的文字與圖片<br>
              2. 建議在內容描述「獎勵誘因」，例如：每推薦一位好友完成註冊，即可獲得折扣！
            </template>
          </ContainerTitle>

          <el-form-item prop="shareMessage">
            <template slot="label">
              <div class="inline-flex justify-between items-center w-full">
                <div class="flex items-center" style="gap: 8px">
                  <p>文字訊息設定</p>
                  <p class="text-danger text-[12px]">建議在內容描述「獎勵誘因」</p>
                </div>
                <el-button plain @click="formData.shareMessage = defaultShareMsg">套用預設文字</el-button>
              </div>
            </template>

            <el-input
              v-model="formData.shareMessage"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }"
              placeholder="請輸入"
              maxlength="200"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label-width="500px">
            <template slot="label">
              <div class="flex items-center" style="gap: 8px">
                <p>文字訊息預覽</p>
                <p class="text-danger text-[12px] text-[#636363]">( 開頭會自動帶入分店訊息 )</p>
              </div>
            </template>

            <div class="content-preview preview-container"><span class="text-primary-100">Hi推薦你成為{分店}會員～</span>{{ formData.shareMessage }}</div>
          </el-form-item>
          <el-form-item label="訊息附圖設定">
            <div class="flex flex-wrap" style="gap: 20px">
              <div
                v-for="(img, index) in formData.ShareImages"
                :key="img.id"
                class="img-wrapper"
              >
                <i
                  class="el-icon-circle-close remove-btn"
                  @click="removeImg(index)"
                />
                <img :src="imgSrc(300, img)" alt="">
              </div>
              <UploadButton
                v-if="formData.ShareImages.length < 2"
                style="width: 90px; height: 90px"
                :img="null"
                @change="imageChange($event)"
              />
            </div>
          </el-form-item>
        </section>
      </el-form>
    </div>

    <ImageCropper
      v-if="modal.cropper"
      :image="imgHolder"
      :ratio="[400, 400]"
      @close="modal.cropper = false"
      @uploaded="getCroppedImage"
    />

    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="onCancel"
      @confirm="onSubmit"
    />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, reactive, ref } from 'vue'
import ContainerTitle from '@/components/Title/ContainerTitle.vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import { get, pick, map, pullAt } from 'lodash'
import { useRoute, useRouter } from 'vue-router/composables'
import { GetMemberInviteConfig, UpdateMemberInviteConfig } from '@/api/memberInvite'
import { simpleSyncFormData, useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { imgSrc } from '@/utils/helper'

export default defineComponent({
  name: 'MemberInviteEdit',
  components: { PageFixedFooter, PageTitle, ContainerTitle, FormItemTooltipLabel, UploadButton, ImageCropper },
  // emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const { shopId } = useShop()
    const { simpleFetch } = useFetch()
    const { initFormData, formData, formRef, loading, formSubmit } = useBaseForm()

    const imgHolder = ref(null)
    const defaultInfoText = `立即分享您的專屬註冊連結
朋友完成「會員註冊」並「綁定 LINE 帳號」
還有機會獲得不定期活動好禮喔！`

    const defaultShareMsg = '點擊下方我的專屬連結，成功加入好友並完成「會員註冊與綁定」不僅可以享受優質服務，將不定期還會加碼優惠活動，這麼好康怎麼能錯過！'
    initFormData({
      displayReferralActivity: false,
      allowReferralRegister: false,
      description: defaultInfoText,
      shareMessage: defaultShareMsg,
      ShareImages: [],
    })
    const loaded = ref(false)
    const modal = reactive({
      cropper: false,
    })

    // 表單欄位規則
    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        description: [noEmptyRules()],
        shareMessage: [noEmptyRules()],
      }
      return rules
    })

    const submitData = computed(() => {
      // const data = pick(formData, [
      //   'allowReferralRegister',
      //   'displayReferralActivity',
      //   'description',
      //   'shareMessage',
      // ])
      const data = {
        allowReferralRegister: get(formData, 'displayReferralActivity'), // 開啟 -> 顯示, 關閉 -> 隱藏
        displayReferralActivity: get(formData, 'displayReferralActivity'),
        description: get(formData, 'description'),
        shareMessage: get(formData, 'shareMessage'),
      }
      console.log(data)
      return {
        shopId: shopId.value,
        ...data,
        shareImageIds: map(formData.ShareImages, 'id'),
      }
    })

    const getCroppedImage = (e) => {
      formData.ShareImages.push(e)
      modal.cropper = false
    }

    const imageChange = (img, level) => {
      imgHolder.value = img
      modal.cropper = true
    }

    const removeImg = (index) => {
      formData.ShareImages.splice(index, 1)
    }

    // 同步表單資料
    const syncFormData = (data) => {
      simpleSyncFormData(data, formData)
    }

    const onCancel = () => {
      router.push({ name: 'MemberInviteSetting' })
    }

    // const handlerChange = (val) => {
    //   const displayReferralActivity = get(formData, 'displayReferralActivity')
    //   if (!val && displayReferralActivity) formData.displayReferralActivity = false
    // }

    // 送出表單
    const onSubmit = async () => {
      const handler = async (data, { emit, loading }) => {
        const [, err] = await UpdateMemberInviteConfig(data)
        loading.value = false
        if (err) {
          window.$message.error(err)
          return
        }
        window.$message.success('更新成功！')
        router.push({ name: 'MemberInviteSetting' })
      }
      await formSubmit(handler, submitData.value, { emit, loading })
    }

    onMounted(async () => {
      await simpleFetch(GetMemberInviteConfig, { shopId: shopId.value }, res => {
        if (res) syncFormData(res)
      })
      loaded.value = true
    })

    return {
      loading,
      formRef,
      formData,
      formRules,
      onCancel,
      // handlerChange,
      onSubmit,
      defaultInfoText,
      defaultShareMsg,
      modal,
      imgHolder,
      getCroppedImage,
      imageChange,
      imgSrc,
      removeImg,
      loaded,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-form-item__label {
    @apply w-full inline-flex items-center;
}

.member-invite-edit {
    @apply pb-[100px];
}

.img-wrapper {
    @apply relative w-[90px] rounded-[4px];

  img {
    @apply rounded-[4px];
  }
}

.remove-btn {
    @apply rounded-[4px] text-white text-[20px];
    @apply cursor-pointer;
    @apply flex justify-center items-center;
    @apply absolute top-0 left-0 right-0 bottom-0;
    background: rgba(0, 0, 0, 0.3);
}

.preview-container {
  @apply leading-[20.27px] text-sub text-[#606266];
  @apply bg-[#F5F7FA] rounded-[4px] border border-gray-40;
  @apply px-[15px] py-[5px] overflow-y-auto max-h-[150px];
}
</style>
